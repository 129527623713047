import { ActivityMenuItemInfo } from 'src/types';

// Auth0 Config variables
export const auth0 = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
};

// API variables
export const API_URL = process.env.REACT_APP_API_URL as string;

// Variable to help with playlist items playing in perpetuity
export const INFINITY_END_DATE = '9999-01-01';

// Generic days of week constant
export const DAYS_OF_THE_WEEK = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export const ICON_DIR =
  'https://waev-frontend-applications.s3.us-east-1.amazonaws.com/shared-assets/activity-menu-icons';

export const ACTIVITY_MENU_ICONS = [
  { name: 'BELL', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'MENU', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'DRINK_MENU', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'BILL', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'CHECKIN', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'REWARD', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'SHARING', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'CALENDAR', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'CATERING', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'FRANCHISING', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'HIRE', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'REVIEW', extension: 'svg' } as ActivityMenuItemInfo,
  { name: 'AMI', extension: 'png' } as ActivityMenuItemInfo,
] as ActivityMenuItemInfo[];
